import React from 'react';

import { AppStateContext } from '../contexts/app-state-provider.context';
import Layout from '../components/layout';
import Video from '../components/video';
import { TranslatableText } from '../components/language';
import { navigate } from 'gatsby';
import Annotation from 'src/components/annotation';

export default class HCPPage extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('hcp');
    this.context.setPage('hcp');
    navigate('/');
  }

  render() {
    return (
      <div className="roche-pages-hcp">
        <Layout
          title={{
            english: 'Patient Resource: HEMLIBRA Administration Video',
            french: 'Ressource pour les patients : vidéo d’administration de HEMLIBRA',
          }}
          showTab={true}
          className="hcp"
        >
          <h2>
            <TranslatableText
              dictionary={{
                english: 'Patient Resource: HEMLIBRA Administration Video',
                french: 'Ressource pour les patients : vidéo d’administration de HEMLIBRA',
              }}
            />
          </h2>
          <Video />
          <Annotation fairBalance={true} hideEducationalPurpose={true} />
        </Layout>
      </div>
    );
  }
}
